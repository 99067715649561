import {
	combineReducers
}from "redux"
import homeReducer from "../../containers/Home/reducer"
import globalReducer from "../../containers/Menu/reducer"
import reAppealTypesReducer from "../../containers/nsi/ReAppealTypes/reducer"
import reThemesOgulReducer from "../../containers/nsi/ReThemesOgul/reducer"
import reSubthemesOgulReducer from "../../containers/nsi/ReSubthemesOgul/reducer"
import reAppealViewTypesReducer from "../../containers/nsi/ReAppealViewTypes/reducer"
import reFormTypesReducer from "../../containers/nsi/ReFormTypes/reducer"
import reRegionReducer from "../../containers/nsi/ReRegions/reducer"
import reDistrictReducer from "../../containers/nsi/ReDistricts/reducer"
import reResponsibleExecutPositionReducer from "../../containers/nsi/ReResponsiblesExecutPosition/reducer"
import reResponsibleExecutReducer from "../../containers/nsi/ReResponsiblesExecut/reducer"
import reResponsibilityTypeReducer from "../../containers/nsi/ReResponsibilityTypes/reducer"
import reRequestStatusReducer from "../../containers/nsi/ReRequestsStatus/reducer"
import mobileReducer from "../../containers/MobilePhoneNumber/store/reducer"
import userReducer from "./user"
import emailReducer from "../../containers/Profile/Email/store/reducer"
import reOperationTypesReducer from "../../containers/nsi/ReOperationTypes/reducer"
import reReasonTypesReducer from "../../containers/nsi/ReReasonTypes/reducer"
import reDocTypesReducer from "../../containers/nsi/ReDocTypes/reducer"
import reSocialGroupTypesReducer from "../../containers/nsi/ReSocialGroupTypes/reducer"
import reGosSubthemesReducer from "../../containers/nsi/ReGosSubthemes/reducer"
import reGosThemesReducer from "../../containers/nsi/ReGosThemes/reducer"
import reGosReducer from "../../containers/nsi/ReGosses/reducer"
import reOrgReducer from "../../containers/nsi/ReOrgs/reducer"
import reLocalityTypeReducer from "../../containers/nsi/ReLocalityTypes/reducer"
import reAdministrativeTerritoryReducer from "../../containers/nsi/ReAdministrativeTerritories/reducer"
import reAddressTypeReducer from "../../containers/nsi/ReAddressTypes/reducer"
import passwordReducer from "../../containers/Profile/Password/store/reducer"
import userHistoryReducer from "../../containers/UserHistory/store/reducer"
import sessionsReducer from "../../containers/Profile/Sessions/store/reducer"
import managementReducer from "../../containers/Management/store/reducer"
import reAddressReducer from "../../containers/nsi/ReAddresses/reducer"
import reCountriesReducer from "../../containers/nsi/ReCountries/reducer"
import reReportKindReducer from "../../containers/nsi/ReReportKinds/store/reducer"
import reReportHierarchyReducer from "../../containers/nsi/ReReportHierarchies/store/reducer"
import reReportReducer from "../../containers/nsi/ReReports/store/reducer"
import reOrgReReportReducer from "../../containers/nsi/ReOrgReReports/store/reducer"
import reOrgReportReOrgReducer from "../../containers/nsi/ReOrgReportReOrgs/store/reducer"
import helpSectionReducer from "../../containers/HelpSection/store/reducer"
import appealUserReducer from "../../containers/AppealUser/store/reducer"
import messageReducer from "../../containers/Messages/store/reducer"
import reContactReducer from "../../containers/Contacts/reducer"
import messageToAdminReducer from "../../containers/MessageToAdmin/reducer"
import newsReducer from "../../containers/News/store/reducer"
import appealsReducer from "../../containers/Appeals/store/reducer"
import calendarReducer from "../../containers/Calendar/store/reducer"
import reInterimReasonReducer from "../../containers/nsi/ReInterimReasons/store/reducer"
import fileReducer from "../../containers/UploadFile/reducer"
import jsonFileReducer from "../../containers/Interaction/reducer"
import fastReportReducer from "../../containers/FastReport/store/reducer"
import applicantNotificationsReducer from "../../containers/ApplicantNotifications/store/reducer"
import reAnswerFormsReducer from "../../containers/nsi/ReAnswerForms/store/reducer"
import resolutionReducer from "../../containers/Appeals/Resolutions/store/reducer"
import collectiveAppealReducer from "../../containers/Appeals/CollectiveAppeal/store/reducer"
import alertReducer from "./alertReducer"
import notificationSettingsReducer from "../../containers/Profile/NotificationSettings/store/reducer"
import reMessageTemplateTypeReducer from "../../containers/nsi/ReMessageTemplateTypes/reducer"
import messageTemplateReducer from "../../containers/MessageTemplates/store/reducer"
import mainPageQuestionReducer from "../../containers/Contacts/MainContactQuestionsSec/reducer"
import reIndexReducer from "../../containers/nsi/ReIndices/store/reducer"
import repetitionReducer from "../../containers/Appeals/Repetition/store/reducer"
import tasksReducer from "../../containers/Task/TableTask/reducer"
import questionReducer from "../../containers/Questions/reducer"
import externalApiReducer from "../../containers/Management/WebApi/ExternalApi/reducer"
import exportToExcelReducer from "../../containers/ExportToExcel/store/reducer"
import reHierarchyLevelReducer from "../../containers/nsi/ReHierarchyLevels/store/reducer"
import reHierarchyLevelReReportReducer from "../../containers/nsi/ReHierarchyLevelReReports/store/reducer"
import aisMvManageReducer from "../../containers/OrgManagement/AisMvManage/store/reducer"
import controlSubsystemReducer from "../../containers/ControlSubsystem/store/reducer"
import accessRightsReducer from "../../containers/AccessRightsAssignment/store/reducer"
import reTypeApplicantNotificationsReducer from "../../containers/nsi/ReTypeApplicantNotification/reducer"
import reOrgStatusTypesReducer from "../../containers/nsi/ReOrgStatusTypes/store/reducer"
import excepsDaysReducer from "../../components/WorkingDayDatePicker/store/reducer"
import superiorReOrgsReducer from "../../containers/Management/SuperiorReOrgs/reducer"
import controlCompleteStatusReducer from "../../containers/nsi/ControlCompleteStatuses/reducer"
import reTaskTypesReducer from "../../containers/nsi/ReTaskTypes/reducer"
import appealsControlReducer from '../../containers/Appeals/OneAppealView/ApeealControlBlock/store/reducer'
import aisMvHandleRegistrationReducer from "../../containers/OrgManagement/AisMvHandleRegistration/reducer";
import {
	routerReducer
}from "react-router-redux"

export default combineReducers({
	appealsControlReducer,
	accessRightsReducer,
	aisMvManageReducer,
	alertReducer,
	appealUserReducer,
	appealsReducer,
	applicantNotificationsReducer,
	calendarReducer,
	collectiveAppealReducer,
	controlSubsystemReducer,
	emailReducer,
	exportToExcelReducer,
	externalApiReducer,
	fastReportReducer,
	fileReducer,
	globalReducer,
	helpSectionReducer,
	homeReducer,
	jsonFileReducer,
	mainPageQuestionReducer,
	managementReducer,
	messageReducer,
	messageTemplateReducer,
	messageToAdminReducer,
	mobileReducer,
	newsReducer,
	notificationSettingsReducer,
	passwordReducer,
	questionReducer,
	reAddressReducer,
	reAddressTypeReducer,
	reAdministrativeTerritoryReducer,
	reAnswerFormsReducer,
	reAppealTypesReducer,
	reAppealViewTypesReducer,
	reContactReducer,
	reCountriesReducer,
	reDistrictReducer,
	reDocTypesReducer,
	reFormTypesReducer,
	reGosReducer,
	reGosSubthemesReducer,
	reGosThemesReducer,
	reHierarchyLevelReReportReducer,
	reHierarchyLevelReducer,
	reIndexReducer,
	reInterimReasonReducer,
	reLocalityTypeReducer,
	reMessageTemplateTypeReducer,
	reOperationTypesReducer,
	reOrgReReportReducer,
	reOrgReducer,
	reOrgReportReOrgReducer,
	reOrgStatusTypesReducer,
	reReasonTypesReducer,
	reRegionReducer,
	reReportHierarchyReducer,
	reReportKindReducer,
	reReportReducer,
	reRequestStatusReducer,
	reResponsibilityTypeReducer,
	reResponsibleExecutPositionReducer,
	reResponsibleExecutReducer,
	reSocialGroupTypesReducer,
	reSubthemesOgulReducer,
	reThemesOgulReducer,
	repetitionReducer,
	resolutionReducer,
	router: routerReducer,
	sessionsReducer,
	tasksReducer,
	userHistoryReducer,
	userReducer,
	reTypeApplicantNotificationsReducer,
	excepsDaysReducer,
	superiorReOrgsReducer,
	controlCompleteStatusReducer,
	reTaskTypesReducer,
	aisMvHandleRegistrationReducer
})
