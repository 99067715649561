import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'
const LoadableAccount = Loadable({
	loader  : () => import('../containers/Account'),
	loading : () => <Loading />
})

const LoadableJsonUpload = Loadable({
	loader  : () => import('../containers/Interaction'),
	loading : () => <Loading />
})

const LoadableMessages = Loadable({
	loader  : () => import('../containers/Messages'),
	loading : () => <Loading />
})

const LoadableUnauthorizedAccess = Loadable({
	loader  : () => import('../components/UnauthorizedAccess'),
	loading : () => <Loading />
})

const LoadableNsi = Loadable({
	loader  : () => import('../containers/nsi'),
	loading : () => <Loading />
})

const LoadableNsiModify = Loadable({
	loader  : () => import('../containers/nsi/nsiModify'),
	loading : () => <Loading />
})

const LoadableFastReportMenu = Loadable({
	loader  : () => import('../containers/FastReport/Menu'),
	loading : () => <Loading />
})

const LoadableConstructure = Loadable({
	loader  : () => import('../containers/FastReport/Constructure'),
	loading : () => <Loading />
})

const LoadableNotifications = Loadable({
	loader  : () => import('../containers/ApplicantNotifications/ListNotifications'),
	loading : () => <Loading />
})
const LoadableTask = Loadable({
	loader  : () => import("../containers/Task"),
	loading : () => <Loading />
})

const LoadableCreateReport = Loadable({
	loader  : () => import('../containers/FastReport/CreateReport/index'),
	loading : () => <Loading />
})

const LoadableIFULRedirect = Loadable({
	loader  : () => import('../containers/IDLlogin/redirect'),
	loading : () => <Loading />
})

const LoadableBelpochtaRedirect = Loadable({
	loader  : () => import('../containers/BelpochtaLogin/redirect'),
	loading : () => <Loading />
})

export const accountLoadables = {
	LoadableAccount,
	LoadableBelpochtaRedirect,
	LoadableConstructure,
	LoadableCreateReport,
	LoadableFastReportMenu,
	LoadableIFULRedirect,
	LoadableJsonUpload,
	LoadableMessages,
	LoadableNotifications,
	LoadableNsi,
	LoadableNsiModify,
	LoadableTask,
	LoadableUnauthorizedAccess
}
